import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import Button from "./Button";
import InputField from "./fields/InputField";
import ButtonWrapper from "Components/ButtonWrapper";

const ProjectBasicFormLayout = styled.form`
  padding: 32px;
  width: 100%;
  textarea {
    width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 100%;
      box-sizing: boarder-box;
    }
    > div:first-child {
      width: 33%;
      padding-right: 20px;
    }
  }
`;

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

class ProjectBasicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      isChanged: false
    };
    this.onNameChange = this.onNameChange.bind(this);
  }

  onNameChange(e) {
    const title = e.target.value;
    this.setState({
      title: title,
      isChanged: true
    });
    this.props.onChange();
  }

  render() {
    const { closeForm, onSave, intl } = this.props;
    return (
      <Layout>
        <ProjectBasicFormLayout aria-label="Project settings">
          <InputField
            onChange={this.onNameChange}
            id="project-name"
            label={intl.formatMessage({ id: "project_name" })}
            value={this.state.title}
          />
          {this.state.isChanged && (
            <ButtonWrapper>
              <Button
                id="save_project_general_settings_button"
                className="primary"
                type="submit"
                aria-label={intl.formatMessage({ id: "save" })}
                onClick={() => this.state.title && onSave(this.state)}
              >
                {intl.formatMessage({ id: "save" })}
              </Button>
              <Button
                id="cancel-project-general-btn"
                type="button"
                className="secondary"
                aria-label={intl.formatMessage({ id: "cancel" })}
                onClick={closeForm}
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </ButtonWrapper>
          )}
        </ProjectBasicFormLayout>
      </Layout>
    );
  }
}

ProjectBasicForm.propTypes = {
  title: PropTypes.string,
  closeForm: PropTypes.func,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  intl: PropTypes.object,
  onChange: PropTypes.func
};

export default injectIntl(ProjectBasicForm);
