import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ContentLayout from "Components/ContentLayout";
import SubNavBar from "Components/SubNavBar";
import ProjectBasicSettingsContainer from "Containers/ProjectBasicSettingsContainer";

const ProjectSettingsPage = styled.div`
  .settings-content {
    width: 100%;
    box-sizing: border-box;
  }
  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .sidebar {
      width: 200px;
    }
    .settings-content {
      width: calc(100% - 200px);
      padding-left: 32px;
      padding-top: 12px;
    }
  }
`;

const ProjectSettingsLayout = styled.div`
  background-color: transparent;
  a.header-arrow-list {
    display: flex;
    width: 100%;
    color: #000;
  }
`;

const getProjectSettingTabs = (organizationId, projectId) => [
  {
    name: "basics",
    route: `/${organizationId}/${projectId}/settings`,
    permission: "#edit"
  },
  {
    name: "access",
    route: `/${organizationId}/${projectId}/settings/access`,
    permission: "#manage-access"
  },
  {
    name: "domains",
    route: `/${organizationId}/${projectId}/settings/domains`,
    permission: "#edit"
  },
  {
    name: "certificates",
    route: `/${organizationId}/${projectId}/settings/certificates`,
    permission: "#edit"
  },
  {
    name: "deploy_key",
    route: `/${organizationId}/${projectId}/settings/deploy_key`
  },
  {
    name: "variables",
    route: `/${organizationId}/${projectId}/settings/variables`,
    permission: "#edit"
  }
];

class ProjectSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      edit: false
    };
  }

  render() {
    return (
      <ContentLayout className="settings-wrapper">
        <ProjectSettingsPage>
          <SubNavBar
            push={this.props.router.push}
            tabs={getProjectSettingTabs(
              this.props.params.organizationId,
              this.props.params.projectId
            )}
            currentPathName={this.props.location.pathname}
            layout="vertical"
          />
          <ProjectSettingsLayout className="settings-content">
            {this.props.children ? (
              this.props.children
            ) : (
              <ProjectBasicSettingsContainer {...this.props.params} />
            )}
          </ProjectSettingsLayout>
        </ProjectSettingsPage>
      </ContentLayout>
    );
  }
}

ProjectSettings.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string,
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  children: PropTypes.node,
  layout: PropTypes.string
};

export default ProjectSettings;
