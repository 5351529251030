import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import ProjectBasicForm from "Components/ProjectBasicForm";
import Heading2 from "Components/styleguide/Heading2";
import SettingLine from "Components/SettingLine";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import ReactTooltip from "react-tooltip";
import Loading from "Components/Loading";

import { updateProject } from "Reducers/project";

const ProjectBasicWrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
  .project-basic-setting {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 10px 15px;
    color: #404041;
    cursor: pointer;
    .project-name,
    .project-owner {
      padding: 0 30px 0 0;
    }
    .project-info {
      display: flex;
      align-items: center;
    }
    .project-name {
      font-size: 16px;
      span {
        min-width: 104px;
      }
    }
    .project-owner {
      font-size: 15px;
    }
  }
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;

  > span:first-of-type {
    width: 50%;
  }

  > span:nth-of-type(2) {
    min-width: 20%;
    padding-left: 16px;
  }
  > span {
    display: inline-flex;
  }
  .project-name {
    flex-direction: column;
    span {
      min-width: 104px;
      width: 104px;
    }
  }
  strong {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  @media (min-width: 980px) {
    .project-name {
      flex-direction: row;
    }
    strong {
      margin-left: 8px;
    }
  }
`;
class ProjectBasicSettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: parseInt(props.currentStep),
      isOpen: false,
      isModalLeaveOpen: false
    };

    this.editForm = this.editForm.bind(this);
    this.onSave = this.onSave.bind(this);
    this.toggle = this.toggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  editForm() {
    this.setState({
      edit: true
    });
  }

  onSave(data) {
    this.props.updateProject(data);
    this.setState({
      isOpen: false,
      edit: false
    });
  }

  toggle() {
    this.state.edit && !this.state.isModalLeaveOpen
      ? this.setState({
          isModalLeaveOpen: true
        })
      : this.setState(prevState => ({
          isOpen: !prevState.isOpen,
          isModalLeaveOpen: false
        }));
  }

  closeModal() {
    this.setState({
      isModalLeaveOpen: false
    });
  }

  render() {
    const { isOpen } = this.state;
    const { organizationId, project, intl, loading } = this.props;

    if (!project) {
      return false;
    }

    return (
      <ProjectBasicWrapper>
        <LiveMessage
          message={`${project.title} basic settings`}
          aria-live="polite"
        />
        <Heading2 id="settings-heading">
          <FormattedMessage id="general" />
        </Heading2>
        <section aria-labelledby="settings-heading">
          {loading && <Loading />}
          <SettingLine
            id="project-basics"
            info={
              <InfoLayout className="project-info">
                <span className="project-name">
                  <span>Project name</span>{" "}
                  <strong
                    id="project-title"
                    data-tip
                    data-for="project-name-full"
                  >
                    {project.title}
                  </strong>
                  <ReactTooltip
                    id="project-name-full"
                    place="bottom"
                    effect="solid"
                  >
                    {project.title}
                  </ReactTooltip>
                </span>
                <span id="project-owner">{organizationId}</span>
              </InfoLayout>
            }
            isOpen={isOpen}
            onClick={this.toggle}
            openText={intl.formatMessage({ id: "edit" })}
          >
            {isOpen && (
              <ProjectBasicForm
                title={project.title}
                closeForm={this.toggle}
                onSave={this.onSave}
                onChange={this.editForm}
              />
            )}
          </SettingLine>
        </section>
        <ModalConfirmLeaveForm
          isOpen={this.state.isModalLeaveOpen}
          closeModal={this.closeModal}
          continueFunction={this.toggle}
          cancelFunction={this.closeModal}
        />
      </ProjectBasicWrapper>
    );
  }
}

ProjectBasicSettingsContainer.propTypes = {
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
  projectTitle: PropTypes.string,
  updateProject: PropTypes.func,
  intl: PropTypes.object,
  currentStep: PropTypes.string,
  project: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  project: state.project.getIn(
    ["data", props.organizationId, props.projectId],
    {}
  ),
  loading: state.project.get("loading", false)
});

const mapDispatchToProps = (dispatch, props) => ({
  updateProject: data =>
    dispatch(updateProject(props.organizationId, props.projectId, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProjectBasicSettingsContainer));
